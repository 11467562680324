import { useNavigate } from "@solidjs/router";
import { createSignal, For, JSX, onCleanup, onMount, Show } from "solid-js";
import { useModal } from "~/components/modal";
import { useSquidContext } from "~/context/squid_context";
import { payOrderStandardCheckout } from "~/data/products";
import { PaymentLoader } from "~/screens/payment_options/payment_loader";
import { clientRepo } from "~/server/apis/client_repo";
import { goBack } from "~/shared_states/modal";
import { snackApiCallWrapper } from "~/shared_states/snackbar";
import { toIndianNumber } from "~/utils/number";
import { openRazorpayCheckout } from "~/utils/third_party/razorpay";
import { Divider } from "~/widgets/divider";
import { PhosphorIcon } from "~/widgets/icons";

enum PaymentMode {
  WALLET,
  UPI,
  CARD,
}

export default function Payment() {
  const { squidDataStore, clearCart } = useSquidContext();

  const { openModal, closeModal } = useModal()!;

  const [initOrderJob, setInitOrderJob] = createSignal(false);
  const [error, setError] = createSignal<string | null>(null);
  const [paymentMode, setPaymentMode] = createSignal<PaymentMode | null>(null);

  const navigator = useNavigate();

  let notesRef: HTMLInputElement | undefined;

  onMount(() => {
    if (squidDataStore.cart.totalAmount == 0) {
      return navigator("/catalogue", { replace: true });
    }
  });

  const confirmationModal = async () => {
    openModal(() => {
      return (
        <div class="flex flex-col justify-center">
          <div class="mb-6  mt-8 flex flex-col items-center justify-center">
            <div class="mb-4 flex h-20 w-20 items-center justify-center rounded-full bg-[#DAF7E8]">
              <PhosphorIcon
                name="shopping-cart-simple"
                fontSize={48}
                class="text-successDark"
                size="light"
              />
            </div>
            <p class="text-f12Bold uppercase text-successDark">Confirm order</p>
            <p class="w-4/5 text-center text-h3 text-textDark">
              ₹
              {squidDataStore.cart.totalAmount -
                squidDataStore.cart.totalDiscount}{" "}
              will be debited from your wallet
            </p>
            <p class="text-medium text-textNormal">
              Current balance: ₹{squidDataStore.walletBalance}
            </p>
            <Show when={error()}>
              <div class="text-medium text-errorDark">{error()}</div>
            </Show>
          </div>
          <div class="w-full border-t border-baseTertiaryDark"></div>
          <div class="mx-2 mt-4 h-10">
            <input
              ref={notesRef}
              type="text"
              class="h-10  w-full rounded-lg bg-baseTertiaryMedium p-2  text-medium text-textDark placeholder:text-medium placeholder:text-textNormal focus:border focus:border-black"
              placeholder="Add a note (Optional)"
              maxLength={40}
            />
          </div>
          <div class=" p-2 pt-4">
            <button
              class={`w-full rounded-lg px-4 py-2.5 text-buttonMedium text-white ${
                initOrderJob() ? "bg-gray-400 opacity-50" : "bg-successDark"
              }`}
              onClick={() => {
                if (initOrderJob()) return;
                initiateOrder();
              }}
            >
              Confirm & pay
            </button>
          </div>
        </div>
      );
    }, "md:w-[380px]");
  };

  const upiPaymentLoader = async (props: {
    amount: number;
    onPaymentComplete: () => void;
    onPaymentFailed: () => void;
    paymentOrderId: string;
    orderId: string;
  }) => {
    openModal(() => {
      return (
        <PaymentLoader
          amount={props.amount}
          onPaymentComplete={props.onPaymentComplete}
          onPaymentFailed={props.onPaymentFailed}
          orderId={props.paymentOrderId}
          brandImageUrl=""
          reInit={() => {}}
          onClose={() => {
            closeModal();
            setInitOrderJob(false);
          }}
          mode="procurement"
          procurementOrderId={props.orderId}
        />
      );
    }, "md:w-full");
  };

  const initiateOrder = async () => {
    setInitOrderJob(true);
    try {
      const result = await clientRepo.initiateSquidOrder({
        totalAmount: squidDataStore.cart.totalAmount,
        products: squidDataStore.cart.products.map((product) => ({
          productId: product.productId,
          amount: product.amount,
          denominationDetails: [
            {
              denomination: product.denomination,
              quantity: product.quantity,
            },
          ],
        })),
        notes: notesRef?.value,
        paymentMode:
          paymentMode() == PaymentMode.WALLET ||
          paymentMode() == PaymentMode.UPI
            ? "UPI"
            : "CARD",
        useWallet: paymentMode() == PaymentMode.WALLET,
      });
      closeModal();
      if (result) {
        if (result.paymentDetails) {
          if (paymentMode() == PaymentMode.UPI) {
            return upiPaymentLoader({
              amount: result.paymentDetails.amount,
              paymentOrderId: result.paymentDetails.id,
              onPaymentComplete: () => {
                clearCart();
                navigator("/order/" + result.id, { replace: true });
              },
              onPaymentFailed: () => {},
              orderId: result.id,
            });
          } else {
            const order = await snackApiCallWrapper(
              payOrderStandardCheckout(result.paymentDetails.id)
            );
            return openRazorpayCheckout({
              amount: result.paymentDetails.amount,
              email: "test@email.com",
              mode: paymentMode() === PaymentMode.CARD ? "CARD" : "UPI",
              orderId: order.providerOrderId,
              phone: "4444112233",
              onSuccess: () => {
                clearCart();
                navigator("/order/" + result.id, { replace: true });
              },
              onDismiss() {
                setInitOrderJob(false);
              },
            });
          }
        }
        clearCart();
        navigator("/order/" + result.id, { replace: true });
      }
    } catch (error: any) {
      console.error("Failed to initiate order:", error);
      setError(error.message);
    }
    setInitOrderJob(false);
  };

  onCleanup(() => {
    setError(null);
  });

  return (
    <div class="flex h-full w-full items-start justify-center overflow-y-auto bg-baseTertiaryMedium py-8">
      <div class="w-full max-w-[944px]">
        <div class="mb-4 flex">
          <PhosphorIcon
            name="arrow-left"
            fontSize={14}
            class="cursor-pointer rounded-full bg-white p-2"
            onClick={() => {
              goBack();
            }}
          />
          <div class="px-3 text-h4 text-textDark">Proceed to pay</div>
        </div>
        <Divider />
        <div class="flex">
          <div class="my-6 flex w-full flex-col gap-3">
            <div class="mb-4 text-smallBold text-textNormal">PAY NOW</div>
            {paymentOption({
              title: "Wallet",
              subtitle:
                "Balance: ₹" + toIndianNumber(squidDataStore.walletBalance),
              payableAmount:
                squidDataStore.cart.totalAmount -
                squidDataStore.cart.totalDiscount,
              icon: "wallet",
              onClick: () => {
                setPaymentMode(PaymentMode.WALLET);
                confirmationModal();
              },
            })}
            {paymentOption({
              title: "UPI",
              subtitle: "Via any UPI app",
              payableAmount:
                squidDataStore.cart.totalAmount -
                squidDataStore.cart.totalDiscount,
              icon: "money",
              onClick: () => {
                setPaymentMode(PaymentMode.UPI);
                confirmationModal();
              },
            })}
            {paymentOption({
              title: "Credit card",
              subtitle: "2% charges applicable",
              payableAmount:
                squidDataStore.cart.totalAmount -
                squidDataStore.cart.totalDiscount,
              icon: "credit-card",
              onClick: () => {
                setPaymentMode(PaymentMode.CARD);
                confirmationModal();
              },
            })}
          </div>
          <div class=" mt-[68px] h-fit w-full max-w-[280px] rounded-[12px] border border-baseTertiaryDark bg-white p-3">
            <div class="text-smallBold text-textNormal">SUMMARY</div>
            <div class="flex w-full items-end">
              <div class="flex w-full flex-col">
                <div class="mt-3 text-f12 text-textNormal">To pay</div>
                <div class="grow text-f12Bold text-textDark">
                  Gift card value
                </div>
              </div>
              <div class="text-bold text-textDark">
                ₹{toIndianNumber(squidDataStore.cart.totalAmount)}
              </div>
            </div>
            <div class="py-2">
              <Divider />
            </div>
            {listProducts()}
          </div>
        </div>
      </div>
    </div>
  );

  function paymentOption(props: {
    icon: string;
    title: string;
    subtitle: string;
    onClick: JSX.EventHandlerUnion<HTMLDivElement, MouseEvent> | undefined;
    payableAmount: number;
  }) {
    return (
      <div class="flex max-w-[640px] gap-3 rounded-[12px] border border-baseTertiaryDark bg-white p-4">
        <div class="flex h-10 w-10 items-center justify-center rounded-full border border-baseTertiaryDark">
          <PhosphorIcon name={props.icon} fontSize={24} size="fill" />
        </div>
        <div class="flex grow flex-col">
          <div class="text-bold">{props.title}</div>
          <div class="text-f12">{props.subtitle}</div>
        </div>
        <div
          class="cursor-pointer rounded-[8px] border border-black px-4 py-[10px] text-mediumBold"
          onClick={props.onClick}
        >
          {"Pay ₹" + toIndianNumber(props.payableAmount)}
        </div>
      </div>
    );
  }

  function listProducts() {
    return (
      <div class="flex flex-col gap-2">
        <For each={squidDataStore.cart.products}>
          {(product) => (
            <div class="flex">
              <div class="grow text-f12Bold">
                {product.detail.brandName +
                  " ₹" +
                  toIndianNumber(product.denomination) +
                  " x " +
                  product.quantity}
              </div>
              <div class="text-f12">{"₹" + toIndianNumber(product.amount)}</div>
            </div>
          )}
        </For>
      </div>
    );
  }
}
